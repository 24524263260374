import React, {useEffect} from 'react';
import {CircularProgress} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {selectForm, uploadFiles} from "../features/form/formSlice";
import {Navigate} from "react-router-dom";
import Container from "@mui/material/Container";
import {Box} from "@mui/system";
import {ReactComponent as BlitzLogo} from "../assets/BlitzLogo.svg";
import {useFiles} from "./FileContext";
import Typography from "@mui/material/Typography";

const UploadingPage = () => {

    const dispatch = useDispatch();
    const {files} = useFiles();
    const formData = useSelector(selectForm);

    useEffect(() => {
        if (!(files.front === '' || files.back === '' || files.photo === '')) {
            dispatch(uploadFiles(files));
        }
    }, []);

    if (formData.fileUpload === 'done') {
        return <Navigate to='/form/6' replace/>
    }
    if (files.front === '' || files.back === '' || files.photo === '') {
        return <Navigate to='/' replace/>
    }

    return (
        <>
            <Box sx={{padding: '16px'}}>
                <BlitzLogo width={120}/>
            </Box>
            <Container maxWidth='xs' sx={{padding: '16px'}} disableGutters>
                { formData.fileUpload === 'rejected' &&
                    (
                        <Typography>{ formData.fileUploadError }</Typography>
                    )
                }
                {
                    formData.fileUpload === 'pending' &&
                    (
                        <CircularProgress/>
                    )
                }
            </Container>
        </>
    );
};

export default UploadingPage;
