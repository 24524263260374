import React from 'react';
import Grid from '@mui/material/Grid';
import 'react-international-phone/style.css';
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";
import Container from "@mui/material/Container";
import DotsStepper from "./DotsStepper";
import {ReactComponent as BlitzLogo} from "../assets/BlitzLogo.svg";
import {Box} from "@mui/system";

const FormPage = ({children, title, subtitle, helper, next, back, step, total, overrideNext=undefined}) => {

    const navigate = useNavigate();
    const handleNext = next ? (() => navigate(next)) : undefined;
    const handleBack = back ? (() => navigate(back)) : undefined;

    return (
        <>
            <Box sx={{padding: '16px'}}>
                <BlitzLogo width={120}/>
            </Box>
            <Container maxWidth='xs' sx={{padding: '16px'}} disableGutters>
                <Grid container spacing={4} sx={{ marginBottom: '64px' }}>
                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Typography variant='h2'>{helper}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='h1'>{title}</Typography>
                                <Typography variant='subtitle1'>{subtitle}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {children}
                    </Grid>
                </Grid>
            </Container>
            <DotsStepper handleBack={handleBack} handleNext={overrideNext ? overrideNext : handleNext} step={step} total={total}/>
        </>
    );
};

export default FormPage;

