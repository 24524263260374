import React, {useEffect} from 'react';
import {CircularProgress} from "@mui/material";
import {useDispatch} from "react-redux";
import {uploadData} from "../features/form/formSlice";
import Container from "@mui/material/Container";
import {Box} from "@mui/system";
import {ReactComponent as BlitzLogo} from "../assets/BlitzLogo.svg";


const StripePage = () => {

    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(uploadData());
    }, []);

    return (
        <>
            <Box sx={{padding: '16px'}}>
                <BlitzLogo width={120}/>
            </Box>
            <Container maxWidth='xs' sx={{padding: '16px'}} disableGutters>
                <CircularProgress/>
            </Container>
        </>
    );
};

export default StripePage;
