import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {loadStripe} from "@stripe/stripe-js";

export const fetchCountryData = createAsyncThunk(
    'form/fetchCountryData',
    async () => {
        const response = await axios.get('https://ipapi.co/json/');
        return response.data;
    }
);


const getBucketLink = async (formData, files) => {
    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/resources/urls`,
        {
            params: {
                customer_id: formData.customer_id,
                additional: files.additional.length
            }
        }
    );
    return response.data;
};


const uploadToPreSigned = async (file, url, headers) => {
    console.log(url, headers);
    return axios.put(
        url,
        file,
        {
            headers: {
                'Content-Type': file.type,
                ...headers
            },
            onUploadProgress: console.log
        },
    )
}


export const uploadFiles = createAsyncThunk(
    'form/uploadFiles',
    async (files, thunkAPI) => {
        const state = thunkAPI.getState().form;
        const presigned = await getBucketLink(state, files);
        await Promise.all([
            uploadToPreSigned(files.front, presigned['front']['url'], presigned['front']['fields']),
            uploadToPreSigned(files.back, presigned['back']['url'], presigned['back']['fields']),
            uploadToPreSigned(files.photo, presigned['photo']['url'], presigned['photo']['fields']),
            ...[
                files.additional.map(
                    (file, index) => uploadToPreSigned(
                        file,
                        presigned['additional'][index]['url'],
                        presigned['additional'][index]['fields']
                    )
                )
            ]
        ]);
    }
)


export const uploadLead = createAsyncThunk(
    'form/uploadLead',
    async (step, thunkAPI) => {
        const state = thunkAPI.getState().form;
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/request`,
                {
                    name: state.name,
                    email: state.email,
                    phone: state.phone
                },
                {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            );
            return response.data;
        }
        catch (e) {
            console.log(e);
        }
    }
)

export const uploadData = createAsyncThunk(
    'form/uploadData',
    async (step, thunkAPI) => {

        const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

        const state = thunkAPI.getState().form;
        const { data: session } = await axios.put(
            `${process.env.REACT_APP_API_URL}/request`,
            {
                customer_id: state.customer_id,
                name: state.name,
                email: state.email,
                phone: state.phone,
                country: state.country,
                city: state.city,
                address_line_1: state.address_line_1,
                address_line_2: state.address_line_2,
                postcode: state.postcode,
                terms: state.terms,
                privacy: state.privacy,
                insurance: state.insurance,
                age: state.age,
                own: state.own,
                range: state.range
            },
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
        const stripe = await stripePromise;
        await stripe.redirectToCheckout({ sessionId: session.id });
    }
)

const formSlice = createSlice({
    name: 'form',
    initialState: {
        customer_id: '',
        name: '',
        email: '',
        phone: '',
        country: '',
        city: '',
        address_line_1: '',
        address_line_2: '',
        postcode: '',
        countryCode: 'gb',
        terms: false,
        privacy: false,
        insurance: false,
        age: false,
        own: false,
        range: '',
        fileUpload: 'none',
        fileUploadError: undefined,
        dataUpload: 'none'
    },
    reducers: {
        updateField: (state, action) => {
            state[action.payload.name] = action.payload.value;
        },
        updateFields: (state, action) => {
            action.payload.forEach(({name, value}) => state[name] = value);
        }
    },
    selectors: {
        selectForm: (state) => state,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCountryData.fulfilled, (state, action) => {
                state.countryCode = action.payload.country_code.toLowerCase();
            })
            .addCase(uploadFiles.pending, (state, action) => {
                state.fileUpload = 'pending';
            })
            .addCase(uploadFiles.rejected, (state, action) => {
                state.fileUpload = 'rejected';
                state.fileUploadError = action.payload;
            })
            .addCase(uploadFiles.fulfilled, (state, action) => {
                state.fileUpload = 'done';
            })
            .addCase(uploadData.pending, (state, action) => {
                state.dataUpload = 'pending';
            })
            .addCase(uploadData.rejected, (state, action) => {
                state.dataUpload = 'failed';
            })
            .addCase(uploadData.fulfilled, (state, action) => {
                state.dataUpload = 'done';
            })
            .addCase(uploadLead.fulfilled, (state, action) => {
                state.customer_id = action.payload.customer_id;
            })
    },
});

export const {updateField, updateFields} = formSlice.actions;
export const {selectForm} = formSlice.selectors;

export default formSlice.reducer;
