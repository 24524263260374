import {Box} from "@mui/system";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import {ReactComponent as BlitzLogo} from "../assets/BlitzLogo.svg";
import Confetti from "react-confetti";


export const SuccessPage = () => {

    const [confetti, setConfetti] = useState(200);
    const [run, setRun] = useState(true);

    useEffect(() => {
        setTimeout(() => setConfetti(0), 1000);
    }, []);

    return (
        <>
            <Confetti numberOfPieces={confetti} run={run} onConfettiComplete={() => setRun(false)}/>
            <Box sx={{padding: '16px'}}>
                <BlitzLogo width={120}/>
            </Box>
            <Container maxWidth='xs' sx={{padding: '16px'}} disableGutters>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Typography variant='h2'></Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='h1'>Success!</Typography>
                                <Typography variant='subtitle1'>We will contact you as soon as possible</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}