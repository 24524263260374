import React from 'react';
import Grid from '@mui/material/Grid';


import {RiderOffer} from './welcome/RiderOffer';

import Container from "@mui/material/Container";
import {MopedSpecs} from "./welcome/MopedSpecs";
import {Header} from "./welcome/Header";
import {useNavigate} from "react-router-dom";


const Welcome = () => {

    const navigate = useNavigate();

    const next = () => navigate('/form/1');

    return (
        <Container sx={{
            padding: {
                xs: '128px 16px 16px 16px',
                md: '64px 16px 16px 16px'
            }
        }} disableGutters>
            <Grid container spacing={8}>
                <Grid item xs={12} md={8}>
                    <Grid container direction='column' rowSpacing={8}>
                        <Grid item xs={12}>
                            <Header next={next}/>
                        </Grid>
                        <Grid item xs={12}>
                            <MopedSpecs/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                    <RiderOffer next={next}/>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Welcome;
