import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import {InputAdornment} from "@mui/material";
import {FlagImage} from "react-international-phone";
import {useDispatch, useSelector} from "react-redux";
import {selectForm, updateField, uploadLead} from "../features/form/formSlice";
import {TextFieldWithValidation} from "./TextFieldWithValidation";
import FormPage from "./FormPage";

const AddressForm = () => {

    const dispatch = useDispatch();
    const formData = useSelector(selectForm);

    useEffect(() => {
        dispatch(uploadLead());
    }, []);

    const [countryValid, setCountryValid] = useState(false);
    const [cityValid, setCityValid] = useState(false);
    const [addressValid, setAddressValid] = useState(false);
    const [postcodeValid, setPostcodeValid] = useState(false);

    const canContinue = countryValid && cityValid && addressValid && postcodeValid;

    return (
        <FormPage
            title='Physical Address'
            subtitle='We only ask for the necessary information. We will never share your personal information.'
            helper={`Nice to meet you, ${formData.name.split(' ')[0]}`}
            step={1}
            total={7}
            next={canContinue ? '/form/3' : undefined}
            back='/form/1'
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextFieldWithValidation
                        disabled
                        isValid={(valid) => setCountryValid(valid)}
                        validation={(value) => value === '' ? 'Please enter country' : ''}
                        variant="outlined"
                        required
                        fullWidth
                        id="country"
                        label="Country"
                        name="country"
                        autoComplete="country"
                        value={formData.country}
                        onChange={(e) => dispatch(updateField({name: 'country', value: e.target.value}))}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    style={{marginRight: '8px', marginLeft: '4px'}}
                                >
                                    <FlagImage iso2={formData.countryCode}/>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextFieldWithValidation
                        isValid={(valid) => setCityValid(valid)}
                        validation={(value) => value === '' ? 'Please enter city' : ''}
                        variant="outlined"
                        required
                        fullWidth
                        id="city"
                        label="City"
                        name="city"
                        autoComplete="city"
                        value={formData.city}
                        onChange={(e) => dispatch(updateField({name: 'city', value: e.target.value}))}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextFieldWithValidation
                        isValid={(valid) => setAddressValid(valid)}
                        validation={(value) => value === '' ? 'Please enter address' : ''}
                        variant="outlined"
                        required
                        fullWidth
                        id="address_line_1"
                        label="Address Line 1"
                        name="address_line_1"
                        autoComplete="address_line_1"
                        value={formData.address_line_1}
                        onChange={(e) => dispatch(updateField({name: 'address_line_1', value: e.target.value}))}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextFieldWithValidation
                        isValid={(valid) => {}}
                        validation={() => ''}
                        variant="outlined"
                        fullWidth
                        id="address_line_2"
                        label="Address Line 2"
                        name="address_line_2"
                        autoComplete="address_line_2"
                        value={formData.address_line_2}
                        onChange={(e) => dispatch(updateField({name: 'address_line_2', value: e.target.value}))}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextFieldWithValidation
                        isValid={(valid) => setPostcodeValid(valid)}
                        validation={(value) => value === '' ? 'Please enter postcode' : ''}
                        variant="outlined"
                        required
                        fullWidth
                        id="postcode"
                        label="Post Code"
                        name="postcode"
                        autoComplete="postcode"
                        value={formData.postcode}
                        onChange={(e) => dispatch(updateField({name: 'postcode', value: e.target.value}))}
                    />
                </Grid>
            </Grid>
        </FormPage>
    );
};

export default AddressForm;
