import 'react-international-phone/style.css';

import {FormControl, FormControlLabel, Radio, RadioGroup,} from '@mui/material';
import React, {useEffect, useState} from 'react';
import axios from "axios";
import TextField from "@mui/material/TextField";


export const DatePicker = ({onPicked}) => {

    const [ranges, setRanges] = useState([]);

    useEffect(() => {

        const fetch = async () => {
            const {data} = await axios.get(
                `${process.env.REACT_APP_API_URL}/available-dates`
            )
            setRanges(data);
        }

        fetch();

    }, []);

    const [later, setLater] = useState(false);
    const [laterValue, setLaterValue] = useState('');

    const handleChange = (e) => {
        const value = e.target.value;
        setLater(value === 'later');
        onPicked(value === 'later', value === 'later' ? laterValue : value);
    }

    const handleLaterChange = (e) => {
        const value = e.target.value;
        setLaterValue(value);
        onPicked(true, value);
    }

    return (
        <FormControl>
            <RadioGroup onChange={handleChange}>
                {ranges.map(item =>
                    (
                        <FormControlLabel
                            control={<Radio/>}
                            label={item.range}
                            key={`${item.range}, ${item.year}`}
                            value={`${item.range}, ${item.year}`}/>
                    )
                )}
                <FormControlLabel value="later" control={<Radio/>} label="Later"/>
                {later &&
                    <TextField
                        label="When?"
                        variant="outlined"
                        value={laterValue}
                        onChange={handleLaterChange}
                        margin="normal"
                        fullWidth
                    />
                }
            </RadioGroup>
        </FormControl>
    );
};

export default DatePicker;