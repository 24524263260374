import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import {NextButton} from "./OrderNowButton";
import {ReactComponent as BlitzLogo} from "../../assets/BlitzLogo.svg";
import {Button, Link} from "@mui/material";


export const Header = ({next}) => {

    return (
        <Grid container spacing={8} alignItems='center'>
            <Grid item xs={12} md={5} style={{textAlign: 'center'}}>
                <a href='https://blitzmotors.com'>
                    <BlitzLogo/>
                </a>
            </Grid>
            <Grid item xs={12} md={7} style={{textAlign: 'center'}}>
                <Typography variant='h1'>Delivery made easy</Typography>
                <Typography variant='subtitle1'>The fastest way to refuel. Unlimited Range.</Typography>
            </Grid>
            <Grid item xs={12} sx={{display: {md: 'none'}}}>
                <NextButton next={next}/>
                <a href={'https://wa.me/32476051991'}>
                    <Button
                        sx={{
                            marginTop: '16px',
                            padding: '20px 16px',
                            borderRadius: '30px',
                            border: '2px solid',
                            borderColor: 'linear-gradient(90deg,  0%, #2154AC 100%)',
                            color: 'linear-gradient(90deg, #0994DC 0%, #2154AC 100%)',
                            width: '100%'
                        }}>
                        CONTACT US
                    </Button>
                </a>
            </Grid>
        </Grid>

    )
};

