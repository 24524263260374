import {styled} from "@mui/system";
import Typography from "@mui/material/Typography";
import React from "react";
import Grid from "@mui/material/Grid";


const SpecContainer = styled('div')(
    () => `
        display: flex;
        flex-direction: row;
        padding: 8px 16px;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
    `,
);

const IconContainer = styled('div')(
    () => `
        display: flex;
        flex-direction: row;
        width: 50px;
        height: 50px;
        justify-content: center;
        align-items: center;
    `,
);

const TextContainer = styled('div')(
    () => `
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
    `,
);


export const Spec = ({label, value, icon}) => {
    return (
        <Grid item xs={12} md={6} lg={6}>
            <SpecContainer>
                <IconContainer>
                    {icon}
                </IconContainer>
                <TextContainer>
                    <Typography variant='overline'>{label}</Typography>
                    <Typography variant='h6'>{value}</Typography>
                </TextContainer>
            </SpecContainer>
        </Grid>
    )
}