import React, {useState} from 'react';
import FormPage from "./FormPage";
import FilePickerButton from "./FilePickerButton";
import {PhotoCamera} from "@mui/icons-material";
import {useFiles} from "./FileContext";

const VerificationForm = () => {

    const { files } = useFiles();

    const [photoPicked, setPhotoPicked] = useState(files['photo'] !== '');

    const onPicked = (name) => {
        if (name === 'photo') {
            setPhotoPicked(true);
        }
    }

    return (
        <FormPage
            title='Take a selfie'
            subtitle="So we can compare it with the your driver's license"
            helper='Time for some pictures'
            step={4}
            total={7}
            next={photoPicked ? '/form/upload' : undefined}
            back='/form/4'
        >
            <FilePickerButton
                name='photo'
                buttonText='Open Camera'
                onPicked={onPicked}
                icon=<PhotoCamera/>
                capture='user'
            />
        </FormPage>
    );
};

export default VerificationForm;
