import React, {useState} from 'react';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import FilePickerButton from "./FilePickerButton";
import {Checkbox, FormControlLabel, FormGroup, Modal} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {selectForm, updateField} from "../features/form/formSlice";
import FormPage from "./FormPage";
import {useFiles} from "./FileContext";
import {Help, HelpCenter, HelpOutline} from "@mui/icons-material";
import {Box} from "@mui/system";
import Container from "@mui/material/Container";


const AddressForm = () => {

    const dispatch = useDispatch();
    const formData = useSelector(selectForm);

    const {files} = useFiles();

    const [frontPicked, setFrontPicked] = useState(files['front'] !== '');
    const [backPicked, setBackPicked] = useState(files['back'] !== '');

    const [frontModal, setFrontModal] = useState(false);
    const [backModal, setBackModal] = useState(false);
    const [cbtModal, setCbtModal] = useState(false);

    const onPicked = (name) => {
        if (name === 'front') {
            setFrontPicked(true);
        } else if (name === 'back') {
            setBackPicked(true);
        }
    }

    const canContinue = frontPicked && backPicked && formData.age && formData.own;

    return (
        <FormPage
            title="Upload Driver's License"
            subtitle='We need to make sure your driving license is valid.'
            helper='Time for some documents'
            step={3}
            total={7}
            next={canContinue ? '/form/5' : undefined}
            back='/form/3'
        >
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <FilePickerButton
                        name='front'
                        buttonText='Front Side'
                        onPicked={onPicked}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4} container justifyContent='right'>
                    <HelpOutline onClick={() => setFrontModal(true)}/>
                    <Modal open={frontModal} onClose={() => setFrontModal(false)}>
                        <Container
                            sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', outline: 'none'}}>
                            <img src='/driver_license_front.png' alt='driver license front template' width='100%'/>
                        </Container>
                    </Modal>
                </Grid>
                <Grid item xs={8}>
                    <FilePickerButton
                        name='back'
                        buttonText='Back Side'
                        onPicked={onPicked}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4} container justifyContent='right'>
                    <HelpOutline onClick={() => setBackModal(true)}/>
                    <Modal open={backModal} onClose={() => setBackModal(false)}>
                        <Container
                            sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', outline: 'none'}}>
                            <img src='/driver_license_back.png' alt='driver license back template' width='100%'/>
                        </Container>
                    </Modal>
                </Grid>
                <Grid item xs={12}>
                    <Typography varient='body1'>
                        If you have an <b>AM category</b> license, we also require your <b>CBT Certificate</b>.
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <FilePickerButton
                        name='additional'
                        buttonText='CBT Certificate'
                        accept='image/*,application/pdf'
                        multiple={true}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4} container justifyContent='right'>
                    <HelpOutline onClick={() => setCbtModal(true)}/>
                    <Modal open={cbtModal} onClose={() => setCbtModal(false)}>
                        <Container
                            sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', outline: 'none'}}>
                            <img src='/cbt_certificate.jpg' alt='cbt certificate template' width='100%'/>
                        </Container>
                    </Modal>
                </Grid>
                <Grid item xs={12}>
                    <FormGroup>
                        <FormControlLabel
                            required
                            control={
                                <Checkbox
                                    onChange={(e) => dispatch(updateField({name: 'age', value: e.target.checked}))}
                                    checked={formData.age}
                                />
                            }
                            label="I’m over 18 years old"
                        />
                        <FormControlLabel
                            required
                            control={
                                <Checkbox
                                    onChange={(e) => dispatch(updateField({name: 'own', value: e.target.checked}))}
                                    checked={formData.own}
                                />
                            }
                            label="This is my own driving license"
                        />
                    </FormGroup>
                </Grid>
            </Grid>
        </FormPage>
    );
};

export default AddressForm;
