import React from 'react';
import {Checkbox, FormControlLabel, FormGroup, Link} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {selectForm, updateField} from "../features/form/formSlice";
import FormPage from "./FormPage";

const TermsForm = () => {

    const dispatch = useDispatch();
    const formData = useSelector(selectForm);

    const canContinue = formData.terms && formData.privacy && formData.insurance;

    return (
        <FormPage
            title='Our Policies'
            subtitle='Your privacy is important to us. We only use your information as outlined in our policy.'
            helper='Just a few more steps'
            step={2}
            total={7}
            next={canContinue ? '/form/4' : undefined}
            back='/form/2'
        >
            <FormGroup>
                <FormControlLabel
                    required
                    control={
                        <Checkbox
                            onChange={(e) => {
                                dispatch(updateField({name: 'terms', value: e.target.checked}));
                            }}
                            checked={formData.terms}
                        />
                    }
                    label={
                        <span>I agree to the <Link href={process.env.REACT_APP_TERMS_CONDITIONS_URL}>Terms and Conditions</Link></span>
                    }
                />
                <FormControlLabel
                    required
                    control={
                        <Checkbox
                            onChange={(e) => dispatch(updateField({
                                name: 'privacy',
                                value: e.target.checked
                            }))}
                            checked={formData.privacy}
                        />
                    }
                    label={
                        <span>I agree to the <Link href={process.env.REACT_APP_PRIVACY_POLICY_URL}>Privacy Policy</Link></span>
                    }
                />
                <FormControlLabel
                    required
                    control={
                        <Checkbox
                            onChange={(e) => dispatch(updateField({
                                name: 'insurance',
                                value: e.target.checked
                            }))}
                            checked={formData.insurance}
                        />
                    }
                    label={
                        <span>I agree to the <Link href={process.env.REACT_APP_INSURANCE_POLICY_URL}>Insurance Policy</Link></span>
                    }
                />
            </FormGroup>
        </FormPage>
    );
};

export default TermsForm;
