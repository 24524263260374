import {styled} from "@mui/system";
import React from "react";
import Grid from "@mui/material/Grid";


const Vertical = styled('div')(
    () => `
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
    `,
);

const Horizontal = styled('div')(
    () => `
        display: flex;
        flex-direction: row;
        align-items: baseline;
        gap: 4px;
    `,
);

const Extra = styled('p')(
    () => `
        color: #1A93D1;
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 0;
        margin: 0;
    `,
);

const Desc = styled('p')(
    () => `
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
        padding: 0;
    `,
);

const PriceValue = styled('p')(
    () => `
        color: #000;
        font-family: Lato;
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
        padding: 0;
    `,
);

const Unit = styled('p')(
    () => `
        color: #404040;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.32px;
        margin: 0;
        padding: 0;
    `,
);


export const Price = ({price, desc, unit, extra = undefined}) => {
    return (
        <Grid item xs={12}>
            <Vertical>
                {extra && <Extra>{extra}</Extra>}
                <Desc>{desc}</Desc>
                <Horizontal>
                    <PriceValue>{'£' + price}</PriceValue>
                    <Unit>{unit}</Unit>
                </Horizontal>
            </Vertical>
        </Grid>
    )
}