import Button from "@mui/material/Button";
import React from "react";

export const NextButton = ({next}) => {
    return <Button
        onClick={next}
        sx={{
            padding: '20px 16px',
            borderRadius: '30px',
            background: 'linear-gradient(90deg, #0994DC 0%, #2154AC 100%)',
            color: 'white',
            width: '100%'
        }}
    >Order your moped now</Button>
}