import React, {useState} from 'react';
import FormPage from "./FormPage";
import DatePicker from "./DatePicker";
import {useDispatch} from "react-redux";
import {updateField} from "../features/form/formSlice";

const PickupForm = () => {

    const dispatch = useDispatch();

    const [picked, setPicked] = useState(false);

    const onPicked = (isCustom, range) => {
        console.log(isCustom, range);
        dispatch(updateField({name: 'range', value: range}));
        setPicked(!(isCustom && range === ''));
    }

    return (
        <FormPage
            title='Choose Pickup Period'
            subtitle='So we can prepare your moped. We will contact you with the final pick-up date.'
            helper='When do you want your moped?'
            step={5}
            total={7}
            next={picked ? '/form/stripe' : undefined}
            back='/form/5'
        >
            <DatePicker onPicked={onPicked}/>
        </FormPage>
    );
};

export default PickupForm;
