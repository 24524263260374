import React, {useEffect, useState} from 'react';
import {Button, FormHelperText} from '@mui/material';
import {styled} from '@mui/system';
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {useFiles} from "./FileContext";


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


const FilePickerButton = ({
                              name,
                              buttonText,
                              onPicked = undefined,
                              accept = 'image/*',
                              multiple = false,
                              icon = <FileUploadIcon/>,
                              capture = undefined,
                              ...props
                          }) => {

    const { files, updateFile } = useFiles();

    const [helper, setHelper] = useState(files[name] === '' ? '' : (multiple ? files[name].map((x) => x.name).join(', ') : files[name].name));

    const handleChange = (e) => {
        if (e.target.files.length > 0) {
            const chosenFiles = [...e.target.files];
            const value = multiple ? chosenFiles : chosenFiles[0];
            updateFile(name, value);
            setHelper(multiple ? chosenFiles.map((x) => x.name).join(', ') : chosenFiles[0].name);
            if (onPicked) {
                onPicked(name);
            }
        }
    }

    const color = helper !== '' ? 'success' : 'primary';

    return (
        <>
            <Button
                color={color}
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={icon}
                {...props}
            >
                {buttonText}
                <VisuallyHiddenInput
                    name={name}
                    onChange={handleChange}
                    type="file"
                    multiple={multiple}
                    accept={accept}
                    capture={capture}/>
            </Button>
            {helper !== '' && <FormHelperText>{helper}</FormHelperText>}
        </>
    );
};

export default FilePickerButton;
