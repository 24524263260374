import React, {useEffect, useState} from "react";
import axios from "axios";
import {Navigate, useLocation, useNavigate, useParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Box} from "@mui/system";
import {useDispatch, useSelector} from "react-redux";
import {selectForm} from "../../features/form/formSlice";
import {selectAdmin, updateAuth} from "../../features/admin/adminSlice";


const fetchCustomer = async (customer_id, username, password) => {
    return axios.get(
        `${process.env.REACT_APP_API_URL}/customers/${customer_id}`,
        {
            headers: {
                'Authorization': `Basic ${btoa(`${username}:${password}`)}`
            }
        }
    );
}

const CenteredMessage = ({children}) => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}
        >
            <Typography variant="h2" align="center">
                {children}
            </Typography>
        </Box>
    );
};


const CustomerPage = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const {id} = useParams();
    const adminData = useSelector(selectAdmin);
    const [data, setData] = useState(undefined);
    const [error, setError] = useState(undefined);

    useEffect(() => {
        const fetch = async (id) => {
            try {
                const response = await fetchCustomer(id, adminData.username, adminData.password);
                setData(response.data);
            }
            catch (e) {
                setError(e);
            }
        }
        fetch(id);
    }, [id]);

    if (error !== undefined) {
        if(error.response.status === 401) {
            return <Navigate to='/admin' state={{ from: location }}/>;
        }
        return <CenteredMessage>Error: {error.message}</CenteredMessage>;
    }
    if (data === undefined) {
        return <CenteredMessage>Loading...</CenteredMessage>;
    }

    const {documents, customer} = data;

    if(Object.keys(documents).length === 0) {
        return <CenteredMessage>No documents uploaded for {customer.name} ({customer.id})</CenteredMessage>;
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <img src={documents.front} alt='front' style={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'cover'
                }}/>
            </Grid>
            <Grid item xs={12} md={4}>
                <img src={documents.back} alt='back' style={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'cover'
                }}/>
            </Grid>
            <Grid item xs={12} md={4}>
                <img src={documents.photo} alt='photo' style={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'cover'
                }}/>
            </Grid>
            {documents.additional.map(add => {
                return (
                    <Grid item xs={12} md={4}>
                        <img src={add} alt='additional' style={{
                            width: '100%',
                            height: 'auto',
                            objectFit: 'cover'
                        }}/>
                    </Grid>
                )
            })}
        </Grid>
    );
};

export default CustomerPage;
