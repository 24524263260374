import {Box} from "@mui/system";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import {ReactComponent as BlitzLogo} from "../../assets/BlitzLogo.svg";
import TextField from "@mui/material/TextField";
import {Button} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {selectAdmin, updatePassword, updateUsername} from "../../features/admin/adminSlice";
import axios from "axios";
import {useLocation, useNavigate} from "react-router-dom";


const AdminPage = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const adminData = useSelector(selectAdmin);
    const [error, setError] = useState('');

    const handleLogin = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/admin/check`,
                {
                    headers: {
                        'Authorization': `Basic ${btoa(`${adminData.username}:${adminData.password}`)}`
                    }
                }
            );
        }
        catch (e) {
            setError('Wrong credentials');
            return;
        }
        const from = location.state?.from?.pathname || '/';
        navigate(from);
    }

    return (
        <>
            <Container maxWidth='xs' sx={{padding: '16px'}} disableGutters>
                <Grid container spacing={4} sx={{ marginBottom: '64px' }}>
                    <Grid item xs={12}>
                        <Box sx={{padding: '16px'}}>
                            <BlitzLogo width={120}/>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Typography variant='h1'>Blitz Admin Login</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    error={error !== ''}
                                    variant="outlined"
                                    fullWidth
                                    id="username"
                                    label="Username"
                                    name="username"
                                    onChange={(e) => dispatch(updateUsername(e.target.value))}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={error !== ''}
                                    variant="outlined"
                                    fullWidth
                                    id="password"
                                    label="Password"
                                    name="password"
                                    onChange={(e) => dispatch(updatePassword(e.target.value))}
                                    helperText={error !== '' ? error : undefined}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    onClick={handleLogin}
                                    component="label"
                                    role={undefined}
                                    variant="contained"
                                    fullWidth>
                                    LOGIN
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default AdminPage;
