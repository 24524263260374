import 'react-international-phone/style.css';

import {debounce, InputAdornment, MenuItem, Select,} from '@mui/material';
import React, {useEffect, useMemo, useState} from 'react';
import {FlagImage, usePhoneInput, defaultCountries, parseCountry} from 'react-international-phone';
import {PhoneNumberUtil} from 'google-libphonenumber';
import {useDispatch, useSelector} from "react-redux";
import {selectForm, updateFields} from "../features/form/formSlice";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {Box} from "@mui/system";

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
    try {
        return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
        return false;
    }
};

const MuiPhone = ({ isValid, value, onChange, ...restProps }) => {

    const dispatch = useDispatch();
    const formData = useSelector(selectForm);

    const [valid, setValid] = useState(isPhoneValid(value));
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        isValid(valid);
    }, [valid]);

    const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } =
        usePhoneInput({
            defaultCountry: formData.countryCode,
            value,
            countries: defaultCountries,
            disableDialCodeAndPrefix: true,
            onChange: ({phone, country}) => {
                setValid(isPhoneValid(phone));
                dispatch(updateFields([
                    {name: 'country', value: country.name},
                    {name: 'countryCode', value: country.iso2},
                    {name: 'phone', value: phone},
                    {name: 'city', value: ''},
                    {name: 'address', value: ''},
                ]));
            },
        });

    return (
        <TextField
            onFocus={() => setShowError(false)}
            onBlur={() => setShowError(true)}
            error={showError && !valid}
            helperText={showError && !valid ? "Invalid phone number" : " "}
            variant="outlined"
            label="Mobile number"
            color="primary"
            value={inputValue}
            onChange={handlePhoneValueChange}
            type="tel"
            inputRef={inputRef}
            InputProps={{
                startAdornment: (
                    <InputAdornment
                        position="start"
                        style={{ marginRight: '2px', marginLeft: '-8px' }}
                    >
                        <Select
                            MenuProps={{
                                style: {
                                    height: '300px',
                                    width: '360px',
                                    top: '10px',
                                    left: '-34px',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                            }}
                            sx={{
                                width: 'max-content',
                                // Remove default outline (display only on focus)
                                fieldset: {
                                    display: 'none',
                                },
                                '&.Mui-focused:has(div[aria-expanded="false"])': {
                                    fieldset: {
                                        display: 'block',
                                    },
                                },
                                // Update default spacing
                                '.MuiSelect-select': {
                                    padding: '8px',
                                    paddingRight: '24px !important',
                                },
                                svg: {
                                    right: 0,
                                },
                            }}
                            value={country.iso2}
                            onChange={(e) => setCountry(e.target.value)}
                            renderValue={(value) => (
                                <FlagImage iso2={value} style={{ display: 'flex' }} />
                            )}
                        >
                            {defaultCountries.map((c) => {
                                const country = parseCountry(c);
                                return (
                                    <MenuItem key={country.iso2} value={country.iso2}>
                                        <FlagImage
                                            iso2={country.iso2}
                                            style={{ marginRight: '8px' }}
                                        />
                                        <Typography marginRight="8px">{country.name}</Typography>
                                        <Typography color="gray">+{country.dialCode}</Typography>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </InputAdornment>
                ),
            }}
            {...restProps}
        />
    );
};

export default MuiPhone;