import {createSlice} from '@reduxjs/toolkit';

const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        username: '',
        password: ''
    },
    reducers: {
        updateUsername: (state, action) => {
            state.username = action.payload;
        },
        updatePassword: (state, action) => {
            state.password = action.payload;
        }
    },
    selectors: {
        selectAdmin: (state) => state,
    }
});

export const {updateUsername, updatePassword} = adminSlice.actions;
export const {selectAdmin} = adminSlice.selectors;

export default adminSlice.reducer;
