import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";


export const TextFieldWithValidation = ({ isValid, value, validation, onChange, ...props }) => {

    useEffect(() => {
        isValid(value !== '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [error, setError] = useState('');

    const onBlur = (e) => {
        const msg = validation(e.target.value);
        setError(msg);
        isValid(msg === '');
    }

    const handleChange = (e) => {
        isValid(validation(e.target.value) === '');
        onChange(e);
    }

    return <TextField
        onFocus={() => setError('')}
        onBlur={onBlur}
        error={error !== ''}
        helperText={error === '' ? ' ' : error}
        value={value}
        onChange={handleChange}
        {...props}
    />
}