import React, {useState} from 'react';
import Grid from '@mui/material/Grid';
import 'react-international-phone/style.css';
import MuiPhone from "./MuiPhone";
import {useDispatch, useSelector} from "react-redux";
import {selectForm, updateField} from "../features/form/formSlice";
import {TextFieldWithValidation} from "./TextFieldWithValidation";
import FormPage from "./FormPage";

const ContactForm = () => {

    const dispatch = useDispatch();
    const formData = useSelector(selectForm);

    const [nameValid, setNameValid] = useState(false);
    const [emailValid, setEmailValid] = useState(false);
    const [phoneValid, setPhoneValid] = useState(false);

    const canContinue = nameValid && emailValid && phoneValid;

    return (
        <FormPage
            title='Contact Information'
            subtitle='So we can let you know when your new electric scooter is ready!'
            helper='Let’s get to know you'
            step={0}
            total={7}
            next={canContinue ? '/form/2' : undefined}
            back='/'
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextFieldWithValidation
                        isValid={(valid) => setNameValid(valid)}
                        value={formData.name}
                        onChange={(e) => dispatch(updateField({name: 'name', value: e.target.value}))}
                        validation={(value) => {
                            if (/\d/.test(value)) return 'Name cannot include numbers';
                            if (!/^\w{2,} \w{1,}.*$/.test(value)) return 'Enter your full name';
                            return '';
                        }}
                        variant="outlined"
                        fullWidth
                        id="name"
                        label="Full Name"
                        name="name"
                        autoComplete="name"/>
                </Grid>
                <Grid item xs={12}>
                    <TextFieldWithValidation
                        isValid={(valid) => setEmailValid(valid)}
                        value={formData.email}
                        onChange={(e) => dispatch(updateField({name: 'email', value: e.target.value}))}
                        validation={(value) => {
                            if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) return 'Invalid email address';
                            return '';
                        }}
                        variant="outlined"
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"/>
                </Grid>
                <Grid item xs={12}>
                    <MuiPhone
                        isValid={(valid) => setPhoneValid(valid)}
                        value={formData.phone}
                        variant="outlined"
                        label="Mobile"
                        color="primary"
                        placeholder="Mobile"
                        type="tel"
                        inputProps={{
                            name: 'phone',
                            required: true
                        }}
                        fullWidth
                        containerStyle={{width: '100%'}}
                        inputStyle={{width: '100%'}}
                    />
                </Grid>
            </Grid>
        </FormPage>
    );
};

export default ContactForm;

