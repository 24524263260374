import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';


export default function DotsStepper({ handleNext, handleBack, step, total }) {
    const theme = useTheme();

    return (
        <MobileStepper
            variant="dots"
            steps={total}
            position="bottom"
            activeStep={step}
            sx={{ maxWidth: 400, flexGrow: 1, margin: 'auto' }}
            nextButton={
                <Button size="small" onClick={handleNext} disabled={handleNext === undefined}>
                    Next
                    {theme.direction === 'rtl' ? (
                        <KeyboardArrowLeft />
                    ) : (
                        <KeyboardArrowRight />
                    )}
                </Button>
            }
            backButton={
                <Button size="small" onClick={handleBack} disabled={handleBack === undefined}>
                    {theme.direction === 'rtl' ? (
                        <KeyboardArrowRight />
                    ) : (
                        <KeyboardArrowLeft />
                    )}
                    Back
                </Button>
            }
        />
    );
}
