import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Price} from "./Price";
import React from "react";
import {NextButton} from "./OrderNowButton";
import {Box} from "@mui/system";
import Check from "@mui/icons-material/Check";
import Container from "@mui/material/Container";

const CheckListItem = ({text}) => {
    return <Grid item xs={12}>
        <Box sx={{display: 'flex', flexDirection: 'row', gap: '4px'}}>
            <Box sx={{display: 'flex', flexDirection: 'row', width: '24px', justifyContent: 'center'}}><Check
                sx={{width: '16px'}}/></Box>
            <Typography variant='body1'>{text}</Typography>
        </Box>
    </Grid>
}


export const RiderOffer = ({next}) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Container style={{
                    background: '#F0F4FA',
                    padding: '32px',
                    borderRadius: '16px',
                }}>
                    <Grid container rowSpacing={4}>
                        <Grid item xs={12}>
                            <Typography variant='h1'>Weekly Subscription</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Price price='80' desc='Starting at' unit='/week'/>
                        </Grid>
                        <Grid item xs={12}>
                            <NextButton next={next}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1.5}>
                                <Grid item xs={12}><Typography variant='body1' fontWeight={700}>This subscription
                                    includes:</Typography></Grid>
                                <CheckListItem text='Blitz 3000X II Moped'/>
                                <CheckListItem text='Phoenix II fast charger'/>
                                <CheckListItem text='2 Powerful PelicanX batteries'/>
                                <CheckListItem text='Large thermal delivery box (180L)'/>
                                <CheckListItem text='Third-party liability insurance'/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Grid item xs={12}>
                <Container style={{
                    padding: '32px',
                }}>
                    <Grid container rowSpacing={4}>
                        <Grid item xs={12}>
                            <Typography variant='h2'>Optional Extensions</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Price
                                price='11'
                                extra='Best for high-mileage riders'
                                desc='Additional PelicanX batteries'
                                unit='/week/unit'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Price
                                price='4'
                                desc='Second Phoenix II fast charger'
                                unit='/week'
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Grid item xs={12}>
                <Container style={{
                    padding: '32px',
                }}>
                    <Typography>Prices do not include VAT</Typography>
                </Container>
            </Grid>
        </Grid>

    )
};

