import {combineReducers, configureStore} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { persistReducer, persistStore } from 'redux-persist';
import formReducer from './features/form/formSlice';
import adminSlice from "./features/admin/adminSlice";

const persistConfig = {
    key: 'root',
    storage,
};

const rootReducer = combineReducers({
    form: formReducer,
    admin: adminSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
});

export const persistor = persistStore(store);
