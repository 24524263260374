import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import {selectAdmin} from "../../features/admin/adminSlice";

const Protected = ({ children }) => {

    const location = useLocation();
    const adminData = useSelector(selectAdmin);

    if(adminData.username === '' || adminData.password === '') {
        return <Navigate to='/admin' state={{ from: location }}/>;
    }

    return children;
};

export default Protected;
