import {Box} from "@mui/system";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import {ReactComponent as BlitzLogo} from "../assets/BlitzLogo.svg";
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";


export const StripeFailedPage = () => {

    const navigate = useNavigate();

    return (
        <>
            <Box sx={{padding: '16px'}}>
                <BlitzLogo width={120}/>
            </Box>
            <Container maxWidth='xs' sx={{padding: '16px'}} disableGutters>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Typography variant='h2'></Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='h1'>Oops</Typography>
                                <Typography variant='subtitle1'>Something is wrong. Please Try again.</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            color='primary'
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            onClick={(e) => navigate('/form/stripe')}
                        >
                            Try Again
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}