import './App.css';
import React, {useEffect} from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import LicenseForm from "./components/LicenseForm";
import VerificationForm from "./components/VerificationForm";
import {blue} from '@mui/material/colors';
import ContactForm from "./components/ContactForm";
import TermsForm from "./components/TermsForm";
import AddressForm from "./components/AddressForm";
import Welcome from "./components/Welcome";
import {useDispatch} from "react-redux";
import {fetchCountryData} from "./features/form/formSlice";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import UploadingPage from "./components/UploadingPage";
import {FileProvider} from "./components/FileContext";
import PickupForm from "./components/PickupForm";
import StripePage from "./components/StripePage";
import {SuccessPage} from "./components/SuccessPage";
import {StripeFailedPage} from "./components/StripeFailedPage";
import AdminPage from "./components/admin/AdminPage";
import CustomerPage from "./components/admin/CustomerPage";
import Protected from "./components/admin/Protected";

const theme = createTheme({
    typography: {
        h1: {
            fontFamily: '"Lato", sans-serif',
            fontSize: 24,
            fontWeight: 700,
            lineHeight: 'normal',
            fontStyle: 'normal',
            margin: 0,
            padding: 0,
        },
        h2: {
            fontFamily: '"Lato", sans-serif',
            fontSize: 16,
            fontWeight: 700,
            lineHeight: 'normal',
            fontStyle: 'normal',
            margin: 0,
            padding: 0,
        },
        subtitle1: {
            fontFamily: '"Lato", sans-serif',
            fontSize: 16,
            fontWeight: 400,
            lineHeight: 'normal',
            fontStyle: 'normal',
            margin: 0,
            padding: 0,
        },
        body1: {
            fontFamily: '"Lato", sans-serif',
            fontSize: 16,
            fontWeight: 400,
            lineHeight: 'normal',
            fontStyle: 'normal',
            margin: 0,
            padding: 0,
        },
        overline: {
            fontFamily: '"Lato", sans-serif',
            fontSize: 14,
            fontWeight: 400,
            color: '#585858',
            lineHeight: 'normal',
            fontStyle: 'normal',
            margin: 0,
            padding: 0,
        },
        h6: {
            fontFamily: '"Lato", sans-serif',
            fontSize: 20,
            fontWeight: 400,
            lineHeight: 'normal',
            fontStyle: 'normal',
            margin: 0,
            padding: 0,
        },
        disclaimer: {
            fontFamily: '"Lato", sans-serif',
            fontSize: 16,
            fontWeight: 400,
            color: '#585858',
            lineHeight: 'normal',
            fontStyle: 'normal',
            margin: 0,
            padding: 0,
        },
        button: {
            fontSize: 16,
            fontFamily: '"Lato", sans-serif',
            letterSpacing: '0.32px',
            fontWeight: 900,
            lineHeight: "normal",
            fontStyle: "normal"
        }
    },
    palette: {
        primary: {
            main: blue[500],
            dark: blue[900]
        },
        secondary: {
            main: blue[800]
        }
    },
    spacing: 8
});


function App() {

    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(fetchCountryData())
    }, []);

    return (
        <FileProvider>
            <ThemeProvider theme={theme}>
                <Router>
                    <Routes>
                        <Route path='/' element={<Welcome/>}/>
                        <Route path='/form/1' element={<ContactForm/>}/>
                        <Route path='/form/2' element={<AddressForm/>}/>
                        <Route path='/form/3' element={<TermsForm/>}/>
                        <Route path='/form/4' element={<LicenseForm/>}/>
                        <Route path='/form/5' element={<VerificationForm/>}/>
                        <Route path='/form/6' element={<PickupForm/>}/>
                        <Route path='/form/upload' element={<UploadingPage/>}/>
                        <Route path='/form/stripe' element={<StripePage/>}/>
                        <Route path='/stripe-success' element={<SuccessPage/>}/>
                        <Route path='/stripe-cancel' element={<StripeFailedPage/>}/>
                        <Route path='/admin' element={<AdminPage/>}/>
                        <Route path='/admin/customers/:id' element={<Protected><CustomerPage/></Protected>}/>
                    </Routes>
                </Router>
            </ThemeProvider>
        </FileProvider>
    );
}

export default App;

