import React, { createContext, useState, useContext } from 'react';

const FileContext = createContext(undefined);

export const useFiles = () => useContext(FileContext);

export const FileProvider = ({ children }) => {

    const [files, setFiles] = useState({
        'front': '',
        'back': '',
        'photo': '',
        'additional': []
    });

    const updateFile = (name, value) => {
        setFiles({
            ...files,
            [name]: value
        });
    }

    return (
        <FileContext.Provider value={{ files, updateFile }}>
            {children}
        </FileContext.Provider>
    );
};
